import { gql, useMutation } from '@apollo/client';

const useReplyActions = () => {
  const [createReplyOnServer, { loading: createLoading, error: creatingError }] = useMutation(gql`
    mutation($replyToId: ID!, $richText: String!) {
      addReply(replyToId: $replyToId, richText: $richText)
    }
  `);

  const [deleteReplyOnServer, { loading: deleteLoading, error: deleteError }] = useMutation(gql`
    mutation($id: String!) {
      removeReply(id: $id)
    }
  `);

  const [updateReplyTextOnServer, { loading: updatingLoading, error: updatingError }] = useMutation(
    gql`
      mutation($id: ID!, $richText: String!) {
        updateReplyText(id: $id, richText: $richText)
      }
    `
  );

  return {
    createReplyOnServer,
    deleteReplyOnServer,
    updateReplyTextOnServer,
    loading: createLoading || deleteLoading || updatingLoading,
    error: creatingError || deleteError || updatingError,
  };
};

export default useReplyActions;
